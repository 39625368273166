import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/654008577e96cdb9ee34023bde62b4ab/0262e/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "141.33333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFwEBAAMAAAAAAAAAAAAAAAAAAgABA//aAAwDAQACEAMQAAAB6ei1sg6VE0WtLQySWv/EAB4QAAIBAwUAAAAAAAAAAAAAAAECABESIQMQMTIz/9oACAEBAAEFAjqNcHMBh71ynB9FGJYtbRt//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BIE//xAAXEQEBAQEAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/AbanGMf/xAAYEAADAQEAAAAAAAAAAAAAAAABEDFxAP/aAAgBAQAGPwIhnWdc6L//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhEDFR/9oACAEBAAE/IQZKH5Exk3jWKBLe8eFkwhFreuYAn//aAAwDAQACAAMAAAAQAD6D/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QasQZ/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAgEBPxDR2biGOwr/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFRkaH/2gAIAQEAAT8QGDYhHMJ8ljW3G7eoHCbMzuJFOmkvpzaMsMdtHsoWH5EjKm1tgHA+wSgqf//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/654008577e96cdb9ee34023bde62b4ab/e5166/01.jpg",
              "srcSet": ["/static/654008577e96cdb9ee34023bde62b4ab/f93b5/01.jpg 300w", "/static/654008577e96cdb9ee34023bde62b4ab/b4294/01.jpg 600w", "/static/654008577e96cdb9ee34023bde62b4ab/e5166/01.jpg 1200w", "/static/654008577e96cdb9ee34023bde62b4ab/0262e/01.jpg 1653w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/08f7269b00b17579c11cca73bf0dce20/0262e/02.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "141.33333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgEA/9oADAMBAAIQAxAAAAHsXVcxgoKws2ThUaVf/8QAGxAAAwADAQEAAAAAAAAAAAAAAAECERMxEkH/2gAIAQEAAQUC9MyJiPkcd0rV0RzXGdciSR//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwGBP//EABkRAQEBAAMAAAAAAAAAAAAAAAEAAhEhQf/aAAgBAgEBPwHyy9Tlg4L/xAAYEAADAQEAAAAAAAAAAAAAAAABEDEAUf/aAAgBAQAGPwLVlEDrmi//xAAdEAACAgMAAwAAAAAAAAAAAAAAAREhMUFhEJGx/9oACAEBAAE/IbHdSLt6JEbVt/TFGh5n0jMiWhzMXzxtzN3Yk4KSEj//2gAMAwEAAgADAAAAEKzMQ//EABgRAAMBAQAAAAAAAAAAAAAAAAABMRAR/9oACAEDAQE/EFMYqdP/xAAXEQEBAQEAAAAAAAAAAAAAAAABADER/9oACAECAQE/EE1IjsnGcC//xAAdEAEBAQEAAgMBAAAAAAAAAAABEQAhMUGBodHh/9oACAEBAAE/EBbTQ4eIpkRiIXozXX3uhdfu3VSF5WQn8GaKELftxcRiITzL74cqlWvXAQD5dwCPO//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/08f7269b00b17579c11cca73bf0dce20/e5166/02.jpg",
              "srcSet": ["/static/08f7269b00b17579c11cca73bf0dce20/f93b5/02.jpg 300w", "/static/08f7269b00b17579c11cca73bf0dce20/b4294/02.jpg 600w", "/static/08f7269b00b17579c11cca73bf0dce20/e5166/02.jpg 1200w", "/static/08f7269b00b17579c11cca73bf0dce20/0262e/02.jpg 1653w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c245d80fdf71307ef09d3a3d0b542d12/0262e/03.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "141.33333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7c5rJpFFytvJIlhf/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIDEgARECIx/9oACAEBAAEFAiceSixvdb9m0wiAVTuw3iec/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGkP//EABYRAQEBAAAAAAAAAAAAAAAAAAAREv/aAAgBAgEBPwHSso//xAAaEAADAAMBAAAAAAAAAAAAAAAAARECICFB/9oACAEBAAY/AiylkHwjxIh892//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhQTFREHGR/9oACAEBAAE/IVGP28KJZW51TFPQaiz8e5QVC9wHK5ag6vyW5G5Xn//aAAwDAQACAAMAAAAQlwsP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EJFYmGv/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFB/9oACAECAQE/EEEBNnfYyZf/xAAfEAEBAAICAQUAAAAAAAAAAAABEQAhMUFREGFxodH/2gAIAQEAAT8QchNeTFxc0Md97xkYGE2zvCFqoj4ZihyozTZxiKk0FXrIBNTlvfzmj9y/cFaD7JkLZgTj0//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/c245d80fdf71307ef09d3a3d0b542d12/e5166/03.jpg",
              "srcSet": ["/static/c245d80fdf71307ef09d3a3d0b542d12/f93b5/03.jpg 300w", "/static/c245d80fdf71307ef09d3a3d0b542d12/b4294/03.jpg 600w", "/static/c245d80fdf71307ef09d3a3d0b542d12/e5166/03.jpg 1200w", "/static/c245d80fdf71307ef09d3a3d0b542d12/0262e/03.jpg 1653w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e3958332cf63db72084280ab959a1bef/0262e/04.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "141.33333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAD/9oADAMBAAIQAxAAAAHsiN8x8oTms9MUNSf/xAAeEAABBAEFAAAAAAAAAAAAAAARAAECEgMTMTJBQv/aAAgBAQABBQIurOGIzWuxUNvfePjpxVIoBf/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABcRAAMBAAAAAAAAAAAAAAAAAAABAhH/2gAIAQIBAT8BdYJ6VLYpxH//xAAbEAACAgMBAAAAAAAAAAAAAAAAECExESJhgf/aAAgBAQAGPwJSaZ8UnHRS/8QAHhAAAwACAQUAAAAAAAAAAAAAAAERITGBQVFhcaH/2gAIAQEAAT8hd3n4cgfyCeCJmD2l8w5g0sN0juLqVt3G115MSAk0R//aAAwDAQACAAMAAAAQZBQ//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERQf/aAAgBAwEBPxDESCT0p//EABkRAQEBAAMAAAAAAAAAAAAAAAEAESFRof/aAAgBAgEBPxDr+yjYPOJgG//EAB4QAQEBAAICAwEAAAAAAAAAAAERACExUZFBYXGh/9oACAEBAAE/EBkCVmeI/ecZAUfgmXNC+QLX+67B99F8r5NySX7gLb1LnnRPDASEa7yMKlWu6Q+3HQg73//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/e3958332cf63db72084280ab959a1bef/e5166/04.jpg",
              "srcSet": ["/static/e3958332cf63db72084280ab959a1bef/f93b5/04.jpg 300w", "/static/e3958332cf63db72084280ab959a1bef/b4294/04.jpg 600w", "/static/e3958332cf63db72084280ab959a1bef/e5166/04.jpg 1200w", "/static/e3958332cf63db72084280ab959a1bef/0262e/04.jpg 1653w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6e96db7f218f972501094b51fe8a8b21/0262e/05.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "141.33333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBAwQF/8QAFwEBAQEBAAAAAAAAAAAAAAAAAgEAA//aAAwDAQACEAMQAAAB7NKpxO0grz17ZwUsKv/EABwQAAICAwEBAAAAAAAAAAAAAAECERIAMkETIf/aAAgBAQABBQKxn0aVMiTfqakfeprRcouRn//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/AYE//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAECESH/2gAIAQIBAT8BtkvhUaKcR//EABsQAAEEAwAAAAAAAAAAAAAAABAAARExIWGh/9oACAEBAAY/Aok6Th8dTilQ/8QAGxABAAMBAQEBAAAAAAAAAAAAAQARITEQUUH/2gAIAQEAAT8haP6MQZjTHvY396+y/k72bgsrbGBG75Xq9PgAcJ//2gAMAwEAAgADAAAAEEcKT//EABgRAAMBAQAAAAAAAAAAAAAAAAABERBB/9oACAEDAQE/EE8hwp//xAAZEQEBAQADAAAAAAAAAAAAAAABABEhMbH/2gAIAQIBAT8QUOPZEaydMnAv/8QAGxABAAMBAQEBAAAAAAAAAAAAAQARITFBkbH/2gAIAQEAAT8QITguwzZf8EOQJXd+QILq+EQvyJMhto1jAQDfIFlStp+RQgAgUub4DfCospstbYFw/WF1Qdn/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/6e96db7f218f972501094b51fe8a8b21/e5166/05.jpg",
              "srcSet": ["/static/6e96db7f218f972501094b51fe8a8b21/f93b5/05.jpg 300w", "/static/6e96db7f218f972501094b51fe8a8b21/b4294/05.jpg 600w", "/static/6e96db7f218f972501094b51fe8a8b21/e5166/05.jpg 1200w", "/static/6e96db7f218f972501094b51fe8a8b21/0262e/05.jpg 1653w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/317c55ce1145aa0dfc632bfa70e65d6b/0262e/06.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "141.33333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAHoOjxOgDXz7NkmcFg3/8QAHhAAAgEDBQAAAAAAAAAAAAAAAQIAAxESEBMiMTL/2gAIAQEAAQUCepVU7jRDcErlwi2s3sResFmI0//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwEYGT//xAAZEQEBAAMBAAAAAAAAAAAAAAABAAIDETH/2gAIAQIBAT8BWPJ1rGPC/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQMREhcf/aAAgBAQAGPwJ4SallGh9mio//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEQITFBcf/aAAgBAQABPyHcWNVON1wuLewFw9gv1PA1hvlx3K9MDgA5P//aAAwDAQACAAMAAAAQgOvD/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERECH/2gAIAQMBAT8Qpj26U//EABoRAQEAAgMAAAAAAAAAAAAAAAEAESExQYH/2gAIAQIBAT8Qbpx7Nw3cROA3/8QAHhABAAICAwADAAAAAAAAAAAAAQARITFBYXFRgZH/2gAIAQEAAT8QGrQHk12RJNKsK0pkmGt3WJbcR2V37HbbvWAaSYHSuXHcD8H1MeW+YuuZa2zTD9YLQon/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/317c55ce1145aa0dfc632bfa70e65d6b/e5166/06.jpg",
              "srcSet": ["/static/317c55ce1145aa0dfc632bfa70e65d6b/f93b5/06.jpg 300w", "/static/317c55ce1145aa0dfc632bfa70e65d6b/b4294/06.jpg 600w", "/static/317c55ce1145aa0dfc632bfa70e65d6b/e5166/06.jpg 1200w", "/static/317c55ce1145aa0dfc632bfa70e65d6b/0262e/06.jpg 1653w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f34772cf49841b4d95484079139acbb2/0262e/07.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "141.33333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAIDAQQF/8QAFwEBAQEBAAAAAAAAAAAAAAAAAgMAAf/aAAwDAQACEAMQAAAB6xBYjfJCoM+UUKG7/8QAHBAAAgICAwAAAAAAAAAAAAAAAAEREgIDITFC/9oACAEBAAEFAr8rYJyeqmEwteLdEdFUVRCP/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQMBAT8BI//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8BVh5ZZf/EABkQAAEFAAAAAAAAAAAAAAAAABAAAREgMf/aAAgBAQAGPwIutE2//8QAHBABAAICAwEAAAAAAAAAAAAAAQARIUExUaFh/9oACAEBAAE/Ib3LMRHhGVrjit3OWKTdbzGCG1dwrMewLIdzPdez4ewLU//aAAwDAQACAAMAAAAQ1Oex/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAREP/aAAgBAwEBPxBq8DLb/8QAGBEBAQADAAAAAAAAAAAAAAAAAQARIVH/2gAIAQIBAT8QI6ZkWHIv/8QAHRABAAMBAAMBAQAAAAAAAAAAAQARITFBYZFRwf/aAAgBAQABPxBr6CTDk/BDxRHtFbDxStaPcEB2jeQhmDqvREFbhEeWBof0wssH8EUVlreKAmL6gLXW9n//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/f34772cf49841b4d95484079139acbb2/e5166/07.jpg",
              "srcSet": ["/static/f34772cf49841b4d95484079139acbb2/f93b5/07.jpg 300w", "/static/f34772cf49841b4d95484079139acbb2/b4294/07.jpg 600w", "/static/f34772cf49841b4d95484079139acbb2/e5166/07.jpg 1200w", "/static/f34772cf49841b4d95484079139acbb2/0262e/07.jpg 1653w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      